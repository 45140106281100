import React, {Component} from 'react';
import {} from 'react-bootstrap'
import styled from 'styled-components';

const DynoColorBlockBox = styled.div`
  width: 100%;
  height: 5vh;
  z-index: 2;
  /* background-color: #4F4E56; */
  /* background-color: #2E294E; */

  background-color: ${props => props.color};
  background-image: url(${props => props.bg});
`;


const DynoColorBlock = ({ color, id }) => {
  return (
    <DynoColorBlockBox color={color} id={id}>
      {}
    </DynoColorBlockBox>
  )
};

export default DynoColorBlock;
//
// export default class Grey extends Component {
//   constructor(props) {
//     super()
//     console.log(GreyBox);
//     GreyBox.backgroundImage = props.bg;
//     console.log(props);
//   }
//   // componentDidMount() {
//   //   console.log(this.props);
//   // }
//
//   render() {
//     return (
//       <>
//         <GreyBox>
//         </GreyBox>
//       </>
//   );
//   }
// }
