import React, {Component} from 'react';
import styled from 'styled-components';

import GameCards from './GameCards.js'


const MidSection = styled.div`
  width: 100%;
  z-index: 2;
  position: relative;
  display: flex;
  flex-grow: 2;
`;

const Info = styled.div`
  width: 100%;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
`;

export default class GameList extends Component {
  render() {
    return (
        <MidSection>
          <Info className="mx-auto">
            <GameCards/>
          </Info>
        </MidSection>
      );
  }
}
