import React, {Component} from 'react';
import {Image, Col} from "react-bootstrap";


export default class Badge extends Component {
    constructor(props) {
        super(props);
        this.icon = props.icon;
        this.link = props.link;
    }

    render() {
        if (this.icon !== "") {
            return (
                <Col>
                    <a target="_blank" href={this.link}> <Image className="gameCardBadge"  src={this.icon}/> </a>
                </Col>
            );
        } else {
            return (<Col/>);
        }
    }
}
