import React from 'react';

import Bar from './Navbar.js'
import Footer from './Footer.js'
import {Card, Col, Image, Container} from "react-bootstrap";
import DynoChop from "./Separators/DynoChop";
import DynoColorBlock from "./Separators/DynoColorBlock";
import styled from "styled-components";

const BannerBuffer = styled.div`
  height: 12vw;
  background-color: #FFEA31;
`;

const AboutPage = (props) => {
    return (
        <>
            <Bar/>
            <BannerBuffer/>
            <Card id="aboutCardYellow" className="aboutCard">
                <Card.Body className="aboutCardBody">
                  <Image fluid className="aboutBanner col-sm-8" src="./Images/Logo/headframe.png"/>
                    <h2 class="aboutTitle">
                        MetaHobby is an independent game studio out of Fremont, Ca.
                    </h2>
                    <Card.Text className="aboutCardText">
                        The studio is made up of three creators: Curt, Mason, and Wyatt.
                        We are programmers, musicians and artists and make everything in-house.<br/><br/>
                        Currently we are focused on smaller projects, like
                        <a target="_blank" href={"https://metahobby.itch.io/hexcross"}> Hexcross</a>.
                        Pixel Smithy, and <a target="_blank" href="https://scav.land">scav.land</a> <br/><br/>

                        We also will develop on contract. We specialize in 2d pixel art, game music, and can develop cross-platform projects using GameMaker Studio: 2 or Godot. <br/><br/>

                        We’re a game development studio, but what is “MetaHobby”? Where does our name come from?<br/>
                        MetaHobby is the hobby of having and finding hobbies.
                        We know that hobbies become so much more. For instance, MetaHobby
                        used to sell yo-yos and kendama, has distributed music as a record label,
                        and even has a weekly meetup for fellow hobby hobbyists.
                        We want to grow our hobbies and help them enrich our lives and our games.
                    </Card.Text>
                </Card.Body>
            </Card>
            <DynoChop
                bg={"./Images/Chops/topyellowchop.svg"}
                color={"#transparent"}
                scale={1}
                id={"yellowChop"}
            />
            <DynoChop
                bg={"./Images/Chops/toppinkchop.svg"}
                color={"#transparent"}
                scale={1}
                id={"scrollGrab"}
            />
            <Card id="aboutCardPink" className="aboutCard">
                <Card.Body className="aboutCardBody" >
                  <Image fluid className="aboutBanner col-sm-8"src="./Images/Logo/metahobbyguys.jpg"/>
                    <Card.Text className="text-center font-weight-bold" text="light">
                        Looking for more info about us and our background?
                        Read our <a target={"_blank"} href={"https://www.42.us.org/students-at-42-silicon-valley-reimagine-games-through-hobbies/"}>article</a> written by 42.us.org
                    </Card.Text>
                </Card.Body>
            </Card>
            <Footer/>
        </>
    );
};

export default AboutPage;
