import React from 'react';
import HomePage from './Components/Homepage/HomePage.js'
import AboutPage from './Components/AboutPage'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
 BrowserRouter as Router,
 Switch,
 Route,
} from "react-router-dom";

const colors = {
  pink: '#EE108B',
  darkPurple: '#2E294E',
  yellow: '#FFEA31',
  slateGrey: '#A2A0B0',
  darkGrey: '#4F4E56'
};

export const ColorContext = React.createContext(colors);

const App = (props) => {
  return (

      <ColorContext.Provider value={colors}>
        <Router>
          <Switch>
            <Route path="/discord" component={() => { 
                window.location.href = 'https://discordapp.com/invite/KcwJ9kj'; 
                return null;
            }}/>
            <Route path="/twitch" component={() => { 
                window.location.href = 'https://www.twitch.tv/metahobby'; 
                return null;
            }}/>
            <Route path="/pixel-smithy-beta" component={() => { 
                window.location.href = 'https://play.google.com/apps/testing/com.metahobby.pixelsmithy'; 
                return null;
            }}/>
            <Route path="/about">
                <AboutPage/>
            </Route>
            <Route path="/">
                <HomePage/>
            </Route>
          </Switch>
        </Router>
    </ColorContext.Provider>
  );
};

export default App;
