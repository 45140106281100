import React, {Component} from 'react';
import styled from 'styled-components';
import {Card, Image, CardGroup, Table, Row, Col} from 'react-bootstrap'
import DynoChop from "../Separators/DynoChop";

export default class BottomSection extends Component {
  render() {
    return (
      <>
        <DynoChop
            bg={"./Images/Chops/toppinkchop.svg"}
            color={"transparent"}
            scale={1}
            id={"darkBlueChop2"}
        />
        <div class="bottomSectionCard">
          <CardGroup>
            <Card text="light" className="bottomCard bg-transparent border-0">
              <Card.Body>
                <Row>
                  <Col className="bottomSectionCol">
                    <Card.Title className="card-title bottomSectionText"><h1>Lets chat on Discord!</h1></Card.Title>
                    <Card.Text className="bottomSectionText card-text">
                        Join our server today! We have a growing community full of<br/> Indie Game Developers, Artists, Musicians, and more...
                    </Card.Text>
                  </Col>
                  <Col className="bottomSectionCol">
                    <a target="_blank" href="https://discord.metahobby.com">
                    <Image src="./Images/Logo/discordlogo.png" id="discord" />
                    </a>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </CardGroup>
        </div>
        {/*<DynoChop*/}
        {/*    bg={"./Images/Chops/bottompinkchop.svg"}*/}
        {/*    color={"transparent"}*/}
        {/*    scale={1}*/}
        {/*    id={"darkBlueChop"}*/}
        {/*/>*/}
      </>
  );
  }
}
