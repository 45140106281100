import React, {Component} from 'react';
import styled from 'styled-components';
import {Card, Row, CardGroup, Container} from 'react-bootstrap'
import Badge from "./Badge";

const CardHolder = styled.div`
  align-items: center;
  position: relative;
`;

export default class GameCards extends Component {
  render() {
    return (
        <CardHolder id="gameCards">
          <CardGroup>
            <Card text="dark" className="gameCard m-4 card-transparent border-0">
            <Card.Img variant="top" src="Images/Games/hexcrosscut.png" />
            <Card.Body className="gameCardBody">
              <Card.Title>Hexcross</Card.Title>
              <Card.Text>
                Avoid getting hexed in this beautiful puzzle dueler.
                Players take turns creating lines trying to avoid certain demise.
                Make a triangle and you're out!
              </Card.Text>
              <Container>
                <Row>
                  <Badge
                      icon="Images/Logo/googlePlay.png"
                      link="https://play.google.com/store/apps/details?id=com.metahobby.hexcross&hl=en_US"
                  />
                  <Badge
                      icon="Images/Logo/itchio.svg"
                      link="https://metahobby.itch.io/hexcross"
                  />
                  <Badge
                      icon="Images/Logo/appStore.svg"
                      link="https://apps.apple.com/us/app/hexcross/id1459594461"
                  />
                </Row>
              </Container>
            </Card.Body>
            </Card>
            <Card text="dark" className="gameCard m-4 card-transparent border-0">
              <Card.Img variant="top" src="Images/Games/colorwarchronocut.png" />
              <Card.Body className="gameCardBody">
                <Card.Title>Color War Chrono: Paint Paradox (Free Demo)</Card.Title>
                <Card.Text>
                  Head to head 2p or 4p dueling! Paint through time to claim dominance over our timeline!
                </Card.Text>
                <Container>
                  <Row>
                    <Badge
                        icon=""
                        link=""
                    />
                    <Badge
                        icon="Images/Logo/itchio.svg"
                        link="https://qst0.itch.io/color-war-chrono-paint-paradox"
                    />
                    <Badge
                        icon=""
                        link=""
                    />
                  </Row>
                </Container>
              </Card.Body>
            </Card>
            <Card text="dark" className="gameCard m-4 card-transparent border-0">
              <Card.Img variant="top" src="Images/Games/pixelsmithcut.png" />
              <Card.Body className="gameCardBody">
                <Card.Title>PixelSmithy (Beta)</Card.Title>
                <Card.Text>
                  Mine your ore, keep your forge hot, cast your weapons, and smithy
                  your way to greatness in this casual clicker game with pinch of skill.
                </Card.Text>
                <Container>
                  <Row>
                    <Badge
                        icon="Images/Logo/googlePlay.png"
                        link="https://play.google.com/apps/testing/com.metahobby.pixelsmithy"
                    />
                    <Badge
                        icon=""
                        link=""
                    />
                    <Badge
                        icon="Images/Logo/appStore.svg"
                        link="https://testflight.apple.com/join/E61FtTZC"
                    />
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </CardGroup>
        </CardHolder>
  );
  }
}
