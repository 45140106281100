import React from 'react';
import {} from 'react-bootstrap'
import styled from 'styled-components';

const DynoChopBox = styled.div`
  height: 20vw;
  width: 100%;
  z-index: 4;
  position:relative;
  transform: scaleY(${props => props.scale});
  background-color: ${props => props.color};
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
`;

const DynoChop = ({ bg, color, scale, id }) => {
  return (
    <DynoChopBox bg={bg} color={color} scale={scale} id={id}/>
  )
};

export default DynoChop;
