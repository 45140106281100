import React from 'react';
import styled from 'styled-components';

import Bar from '../Navbar.js'
import Banner from './Banner.js'
import GameList from './GameList.js'
import DynoChop from '../Separators/DynoChop.js'
import BottomSection from './BottomSection.js'

const BannerBuffer = styled.div`
  height: 15vh;
`;

const HomePage = (props) => {
    return (
      <>
        <BannerBuffer/>
        <Bar/>
        <div/>
        <Banner/>
          <DynoChop
            bg={"./Images/Chops/singlechop.svg"}
            color={"transparent"}
            scale={1}
            id={"scrollGrab"}
          />
          <GameList/>
          <BottomSection/>
      </>
  );
};

export default HomePage;
