import React, {Component} from 'react';
import styled from 'styled-components';
import {Carousel} from 'react-bootstrap'

const Title = styled.h1`
  font-size: 1.5em;
  text-align: Left;
  color: #EE108B;
`;

const Wrapper = styled.section`
  position: relative;
  /* background: #2E294E; */
  width: 100%;
  align-items: center;
  margin: 0 auto;
  /* position: fixed; */
  /* height: 80% */
`;

export default class Banner extends Component {
  render() {
    return (
    <Wrapper id="banner" className="mx-auto">
    <Carousel className="mx-auto w-100">
      <Carousel.Item>
        <img
          className="d-block w-75 mx-auto"
          src="Images/Games/hexcrosscut.png"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3></h3>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-75 mx-auto"
          src="Images/Games/colorwarchronocut.png"
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3></h3>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-75 mx-auto"
          src="Images/Games/pixelsmithcut.png"
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3></h3>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </Wrapper>
  );
  }
}
