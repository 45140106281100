import React, {Component} from 'react';
import DynoChop from './Separators/DynoChop.js'
import { Navbar, Nav, Image } from 'react-bootstrap'
import styled from 'styled-components';


const Title = styled.h1`
  font-size: 1.5em;
  text-align: Left;
  color: var(--main-accent);
  margin-left: 5%;
`;

const Wrapper = styled.div`
  position: relative;
  padding: 1vw;
  width: 100%;
  height: 5vw;
  margin: 0 auto;
  top: 0
`;

export default class Bar extends Component {
  componentDidMount() {
    // if (window.location.pathname === "/") {
      window.addEventListener("scroll", this.handleScroll);
    // }
    document.getElementById('navboi').style.backgroundColor = "#FFEA31";
    //document.getElementById('navboi').style.position = "fixed";
  }

  handleScroll = () => {
    // if (window.location.pathname === "/") {
      var top = document.getElementById("scrollGrab").offsetTop;
      var scrollPos = document.documentElement.scrollTop || document.body.scrollTop;
      console.log(window.location.pathname);
      if (scrollPos <= 250) {
        document.getElementById('navboi').style.backgroundColor = "#FFEA31";
        document.getElementById('logo').src = "Images/Logo/logoblack.png"
        var list = document.getElementsByClassName('navDesc')
        for (var i = 0; i < list.length; i++) {
            list[i].style.color = "black";
        }
          }
      else if (scrollPos <= top && scrollPos > 50) {
        document.getElementById('navboi').style.backgroundColor = "#FFEA31";
        document.getElementById('logo').src = "Images/Logo/logoblack.png"
        var list = document.getElementsByClassName('navDesc')
        for (var i = 0; i < list.length; i++) {
            list[i].style.color = "black";
        }
          }
      else {
        document.getElementById('navboi').style.backgroundColor = "#EE108B";
        document.getElementById('logo').src = "Images/Logo/logowhite.png"
        var list = document.getElementsByClassName('navDesc')
        for (var i = 0; i < list.length; i++) {
            list[i].style.color = "#FBF5F3";
        }
      }
    // }
  };
  render() {
    return (
      <>
        <Navbar id="navboi" className="fixed-top" expand="lg">
          <Navbar.Brand className="navDesc" href="#home"><Image id="logo" src="./Images/Logo/logoblack.png"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link className="navDesc" href="/">Home</Nav.Link>
              <Nav.Link className="navDesc" href="/about">About</Nav.Link>
            </Nav>
          </Navbar.Collapse>
      </Navbar>
    </>
  );
  }
}

// <NavDropdown id= title="Dropdown" id="basic-nav-dropdown">
//   <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
//   <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
//   <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
//   <NavDropdown.Divider />
//   <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
// </NavDropdown>
