import React, {Component} from 'react';
import {Navbar, Nav, NavDropdown, Form, FormControl, Button} from 'react-bootstrap'
import styled from 'styled-components';

const Title = styled.h1`
  font-size: 1.5em;
  text-align: Left;
  color: var(--main-accent);
  margin-left: 5%;
`;

export default class Footer extends Component {

  constructor() {
    super();
    
  }

  componentDidMount() {

    window.addEventListener("scroll", this.handleScroll)
    document.getElementById('navboi').style.backgroundColor = "#FFEA31";
    //document.getElementById('navboi').style.position = "fixed";
  }

  handleScroll = () => {
    if (window.location.pathname === "/") {
      var top = document.getElementById("scrollGrab").offsetTop + 100;
      var scrollPos = document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollPos <= 50) {
        document.getElementById('footer').style.backgroundColor = "#FFEA31";
        document.getElementById('logo').src = "Images/Logo/logoblack.png"
      }
      else if (scrollPos <= top && scrollPos > 50) {
        document.getElementById('footer').style.backgroundColor = "#FFEA31";
      }
      else {
        document.getElementById('footer').style.backgroundColor = "#EE108B";
      }
    }
  };
  render() {
    return (
      <Navbar id="footer" className="fixed-bottom" expand="lg">
      </Navbar>
  );
  }
}

// <Navbar.Brand className="navDesc" href="#home"><img id="logo" src="Images/Logo/logoblack.png"/></Navbar.Brand>
// <Navbar.Toggle aria-controls="basic-navbar-nav" />
// <Navbar.Collapse id="basic-navbar-nav">
//   <Nav className="mr-auto">
//     <Nav.Link className="navDesc" href="#home">Home</Nav.Link>
//     <Nav.Link className="navDesc" href="#link">Link</Nav.Link>
//   </Nav>
// </Navbar.Collapse>
